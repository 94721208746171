/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function Checkbox({
  isChecked,
  onClick,
  label,
  labelStyle,
  isDisabled = false,
  big,
}) {
  return (
    <div
      className={classNames(classes.Checkbox, {
        [classes.big]: big,
      })}
    >
      <label
        className={classNames(classes.container, {
          [classes.disabled]: isDisabled,
        })}
      >
        <input
          type="checkbox"
          checked={isChecked}
          onChange={onClick}
          disabled={isDisabled}
        />
        <span
          className={classNames(classes.checkmark, {
            [classes.disabled]: isDisabled,
          })}
        />
        <span style={{ ...labelStyle }} className={classes.label}>
          {label}
        </span>
      </label>
    </div>
  );
}
