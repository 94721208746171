/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import SvgArrow from '../CreateMeetingFooter/SectionMenu/SvgArrow';

import noteIcon from '../../assets/images/section-menu/notes.svg';
import audioPlaceholder from '../../assets/images/section-menu/audio-placeholder.png';
import questionSection from '../../assets/images/section-menu/question-section.png';
import classes from './styles.module.scss';
import { useUiStore } from '../../store';
import adjustTooltipOnZoom from '../../helpers/adjustTooltipOnZoom';
import usePreviewSections from '../../hooks/usePreviewSections';

// const SECTION_WIDTH = 336;
const SECTION_WIDTH = 235;

export default function PreviewSections({
  data,
  videoRef,
  animateProgressBar,
  previewSectionsRef,
  setQuestionToShow,
  setIsQuestionModalVisible,
  setPreviousShownQuestions,
  setPreviousShownNote,
  setIsPlaying,
}) {
  const [sectionsPageCount, setSectionsPageCount] = useState(0);
  const [currentSectionsPage, setCurrentSectionsPage] = useState(0);
  const [width, setWidth] = useState(0);

  const sectionsListContainerRef = useRef();
  const leftSwitchRef = useRef();

  const isSidebarCollapsed = useUiStore((state) => state.isSidebarCollapsed);

  const zoom = getComputedStyle(document.documentElement)
    .getPropertyValue('--zoom')
    .trim();
  const zoomedSectionWidth = SECTION_WIDTH * zoom;

  useEffect(() => {
    const getWidth = () => {
      const sidebarWidth = isSidebarCollapsed ? 126 * zoom : 373 * zoom;

      const w = window.innerWidth - sidebarWidth - 42 - 46;

      setWidth(w);
    };

    getWidth();

    window.addEventListener('resize', getWidth);

    return () => {
      window.removeEventListener('resize', getWidth);
    };
  }, [isSidebarCollapsed, zoom]);

  const sectionsCount = Math.floor(width / (zoomedSectionWidth + 40)) || 1;

  const scrollSections = (direction) => {
    if (direction === 'right') {
      if (currentSectionsPage === 0) {
        return;
      }
      setCurrentSectionsPage((prevPage) => prevPage - 1);
    } else {
      if (sectionsPageCount === currentSectionsPage) {
        return;
      }
      setCurrentSectionsPage((prevPage) => prevPage + 1);
    }
  };

  const { handleRewindToSection, currentSectionOrder } = usePreviewSections({
    data,
    videoRef,
    animateProgressBar,
    setQuestionToShow,
    setIsQuestionModalVisible,
    setPreviousShownQuestions,
    setPreviousShownNote,
    setIsPlaying,
  });

  useEffect(() => {
    setSectionsPageCount(Math.max(data?.Sections.length - sectionsCount, 0));
  }, [data?.Sections, sectionsCount]);

  // console.log(sectionsStartPoints);

  return (
    <div className={classes.sections} ref={previewSectionsRef}>
      <div
        className={classNames(classes.switchLeft, classes.switch)}
        onClick={() => scrollSections('right')}
        ref={leftSwitchRef}
      >
        <SvgArrow />
      </div>
      <div
        className={classNames(classes.switchRight, classes.switch)}
        onClick={() => scrollSections('left')}
      >
        <SvgArrow />
      </div>

      <div
        className={classes.sectionsListContainer}
        ref={sectionsListContainerRef}
      >
        <ul
          className={classes.sectionsList}
          style={{
            width: (SECTION_WIDTH + 40) * sectionsCount,
          }}
        >
          <div
            className={classes.inner}
            style={{
              left: currentSectionsPage * -(SECTION_WIDTH + 40),
            }}
          >
            {data?.Sections.map((section, index) => {
              let sectionContent = (
                <div className={classes.sectionContent}>
                  <video src={section.signedS3Url} />
                </div>
              );

              if (section.type === 'assigned-section') {
                return;
              }

              if (section.type === 'audio') {
                sectionContent = (
                  <div className={classes.sectionContent}>
                    <img src={audioPlaceholder} alt="Audio" />
                  </div>
                );
              }

              if (section.type === 'question') {
                sectionContent = (
                  <div className={classes.sectionContent}>
                    <img src={questionSection} alt="Question" />
                  </div>
                );
              }

              if (section.type === 'note') {
                sectionContent = (
                  <div
                    className={classNames(
                      classes.sectionContent,
                      classes.noteSectionContent
                    )}
                  >
                    <img
                      src={noteIcon}
                      alt="Note"
                      style={{ width: 100, height: 'auto' }}
                    />
                  </div>
                );
              }

              const isCurrent = currentSectionOrder === index;

              return (
                <li
                  key={section.id}
                  className={classNames(classes.sectionItem, {
                    [classes.current]: isCurrent,
                  })}
                  onClick={() => handleRewindToSection(section, index)}
                >
                  {sectionContent}
                  <OverlayTrigger
                    placement="top"
                    onToggle={adjustTooltipOnZoom}
                    overlay={<Tooltip>{section.title}</Tooltip>}
                  >
                    <div className={classes.title}>
                      <span>{section.title}</span>
                    </div>
                  </OverlayTrigger>
                </li>
              );
            })}
          </div>
        </ul>
      </div>
    </div>
  );
}
