import React from 'react';

import { useMediaQuery } from 'react-responsive';

import Progress from '../../Library/MeetingRow/Progress';

export default function TitleWithProgress({
  classes,
  title,
  isMeetingPublished,
  lastPublishedDate,
  processing,
}) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div
      className={classes.titleContainer}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <span className={classes.titleText}>{title}</span>
      {isMeetingPublished ? (
        <span
          className={classes.publishedDate}
          style={{ paddingLeft: isMobile ? 20 : '' }}
        >
          {lastPublishedDate}
        </span>
      ) : (
        <Progress
          isVisible={processing}
          percentage={processing?.percentage}
          step={processing?.step}
        />
      )}
    </div>
  );
}
