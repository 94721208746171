import React from 'react';

import classes from './styles.module.scss';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';
import emptyFolderIcon from '../../../assets/images/my-library/emptyFolder.svg';

export default function EmptyFolderMessage() {
  const isMobile = useCheckMobileScreen();

  if (!isMobile) {
    return 'This folder is empty';
  }

  return (
    <div className={classes.EmptyFolderMessage}>
      <div className={classes.emptyFolderIconContainer}>
        <img src={emptyFolderIcon} alt="Empty folder" />
      </div>
      <span className={classes.message}>This folder is empty</span>
    </div>
  );
}
