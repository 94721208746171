/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';
import audioPlaceholder from '../../assets/images/section-menu/audio-placeholder.png';
import questionSection from '../../assets/images/section-menu/question-section.png';
import noteIcon from '../../assets/images/section-menu/notes.svg';
import usePreviewSections from '../../hooks/usePreviewSections';

export default function PreviewSectionsMobile({
  data,
  videoRef,
  animateProgressBar,
  setQuestionToShow,
  setIsQuestionModalVisible,
  setPreviousShownQuestions,
  setPreviousShownNote,
  setIsPlaying,
}) {
  const { handleRewindToSection, currentSectionOrder } = usePreviewSections({
    data,
    videoRef,
    animateProgressBar,
    setQuestionToShow,
    setIsQuestionModalVisible,
    setPreviousShownQuestions,
    setPreviousShownNote,
    setIsPlaying,
  });

  return (
    <ul className={classes.PreviewSectionsMobile}>
      {data?.Sections.map((section, index) => {
        let sectionContent = (
          <div className={classes.sectionContent}>
            <video src={section.signedS3Url} />
          </div>
        );

        if (section.type === 'assigned-section') {
          return;
        }

        if (section.type === 'audio') {
          sectionContent = (
            <div className={classes.sectionContent}>
              <img src={audioPlaceholder} alt="Audio" />
            </div>
          );
        }

        if (section.type === 'question') {
          sectionContent = (
            <div className={classes.sectionContent}>
              <img src={questionSection} alt="Question" />
            </div>
          );
        }

        if (section.type === 'note') {
          sectionContent = (
            <div
              className={classNames(
                classes.sectionContent,
                classes.noteSectionContent
              )}
            >
              <img src={noteIcon} alt="Note" style={{ objectFit: 'contain' }} />
            </div>
          );
        }

        const isCurrent = currentSectionOrder === index;

        return (
          <li
            key={section.id}
            className={classNames(classes.sectionItem, {
              [classes.current]: isCurrent,
            })}
            onClick={() => handleRewindToSection(section, index)}
          >
            {sectionContent}

            <div
              className={classNames(classes.title, {
                [classes.current]: isCurrent,
              })}
            >
              <span>{section.title}</span>
            </div>
          </li>
        );
      })}
    </ul>
  );
}
