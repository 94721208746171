import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import Navigation from '../../components/Navigation';
import Breadcrumbs from '../../components/Breadcrumbs';
import MeetingsList from '../../components/MeetingsList';
import MeetingHistoryList from '../../components/MeetingHistoryList';
import Subfolder from '../../components/Library/Subfolder';
import Layout from '../../containers/Layout';

import MeetingService from '../../services/MeetingService';
import { MEETING_TABS } from '../../constants/main';
import classes from './styles.module.scss';
import folderIcon from '../../assets/images/nav/my-library-active.svg';
import arrowUpIcon from '../../assets/images/arrow-up.svg';
import { useUiStore } from '../../store';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

const MEETING_TABS_LABELS = {
  /* addition to url to get info from tab */
  [MEETING_TABS.MY_VIDEOS]: 'My Videos',
  [MEETING_TABS.MOBILE_UPLOADS]: 'Mobile Uploads',
  [MEETING_TABS.SAVED]: 'My Saved Sections',
};

const subfolders = [
  /* removes the tab */
  /* { tab: MEETING_TABS.MY_VIDEOS, title: 'My Videos' }, */
  { tab: MEETING_TABS.MOBILE_UPLOADS, title: 'My Mobile Uploads' },
  { tab: MEETING_TABS.SAVED, title: 'My Saved Sections' },
];

export default function MeetingsPage() {
  const [searchInput, setSearchInput] = useState('');
  const [selectedTab, setSelectedTab] = useState('my videos');
  /*  const [breadcrumbsOptions, setBreadcrumbsOptions] = useState([
    { label: 'My Videos', to: '/my-videos' },
  ]); */
  const [title, setTitle] = useState('');

  const { id: meetingId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const destinationMeetingId = queryParams.get('meetingId');
  const prevSectionId = queryParams.get('prevSectionId');

  const isMobile = useCheckMobileScreen();

  const breadcrumbsOptions = useUiStore((state) => state.breadcrumbsOptions);
  const setBreadcrumbsOptions = useUiStore(
    (state) => state.setBreadcrumbsOptions
  );

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);

  const { data: meeting } = useQuery(
    ['meeting', meetingId],
    () => MeetingService.getMeeting(meetingId),
    {
      enabled: !!meetingId,
      refetchOnWindowFocus: false,
    }
  );

  const handleChangeTab = useCallback(
    (tab) => {
      navigate(`/my-videos?tab=${tab}`);
    },
    [navigate]
  );

  const isHistoryPage = location.pathname.endsWith('/history');

  useEffect(() => {
    const tab = queryParams.get('tab');

    const defaultOptions = [{ label: 'My Videos', to: '/my-videos' }];
    const tempOptions = [...defaultOptions];

    if (tab) {
      setSelectedTab(tab);
      setTitle(MEETING_TABS_LABELS[tab]);
      tempOptions.push({
        label: MEETING_TABS_LABELS[tab],
        to: `/my-videos?tab=${tab}`,
      });
    } else if (isHistoryPage && meeting) {
      setSelectedTab(MEETING_TABS.MY_VIDEOS);
      tempOptions.push({
        label: MEETING_TABS_LABELS[MEETING_TABS.MY_VIDEOS],
        to: `/my-videos?tab=${MEETING_TABS.MY_VIDEOS}`,
      });
      setTitle(meeting.title);
    } else {
      setSelectedTab('my videos');
      setTitle('');
    }

    setBreadcrumbsOptions(tempOptions);
  }, [location, meeting, isHistoryPage]);

  // console.log('meetingId', meetingId, selectedTab);

  let content = (
    <MeetingsList
      classes={classes}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      tab={selectedTab}
      destinationMeetingId={destinationMeetingId}
      prevSectionId={prevSectionId}
    />
  );

  if ((meetingId && selectedTab !== 'my videos') || isHistoryPage) {
    content = <MeetingHistoryList classes={classes} meetingId={meetingId} />;
  }

  return (
    <Layout
      hasUserBlock
      hasSearch
      searchInput={searchInput}
      setSearchInput={setSearchInput}
    >
      <div className={classes.MeetingsPage}>
        <Navigation />
        <div className={classes.container}>
          <div className={classes.breadcrumbsContainer}>
            <Breadcrumbs
              breadcrumbsOptions={breadcrumbsOptions}
              showBackButton={!!destinationMeetingId}
            />
            <h1 className={classes.title} style={{ display: 'none' }}>
              {title}
            </h1>
          </div>
          {selectedTab === 'my videos' && !isHistoryPage && (
            <div className={classes.foldersContainer}>
              {subfolders.map((subfolder, index) => (
                <Subfolder
                  key={subfolder.tab}
                  onClick={() => handleChangeTab(subfolder.tab)}
                  title={subfolder.title}
                  icon={index === 0 ? arrowUpIcon : folderIcon}
                  style={{
                    borderRight: index === 0 && !isMobile ? 'none' : '',
                    borderLeft: index === 1 && !isMobile ? 'none' : '',
                    borderTopRightRadius: index === 0 && !isMobile ? '0' : '',
                    borderTopLeftRadius: index === 1 && !isMobile ? '0' : '',
                    borderBottomRightRadius:
                      index === 0 && !isMobile ? '0' : '',
                    borderBottomLeftRadius: index === 1 && !isMobile ? '0' : '',
                    width: !isMobile ? 345 : '',
                  }}
                />
              ))}
            </div>
          )}
          {content}
        </div>
      </div>
    </Layout>
  );
}
