/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import { useDebounce } from 'use-debounce';

import SummaryViewerReportModal from '../../modals/SummaryViewerReportModal';
import Th from '../Th';
import Row from './Row';
import CreateMeetingMobileButton from '../CreateMeetingMobileButton';
import EmptyFolderMessage from '../Library/EmptyFolderMessage';

import { UiContext } from '../../context/UiContext';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import MeetingService from '../../services/MeetingService';
import UserService from '../../services/UserService';
import copyFolderLink from '../../helpers/copyFolderLink';
import MeetingDetailsModal from '../../modals/MeetingDetailsModal';
import useMeetingProcessing from '../../hooks/useMeetingProcessing';
import { ORG_ROLES } from '../../constants/main';
import useMeetingReady from '../../hooks/useMeetingReady';

const rowHeight = 70;
const restHeight = 112 + 108 + 44 + 64;
const mobileItemsRange = Math.floor(
  (window.innerHeight - restHeight) / rowHeight
);

const ths = [
  {
    title: 'Name',
    field: 'title',
  },
  {
    title: 'Owner',
    field: 'ownerName',
  },
  {
    title: 'Date Uploaded',
    field: 'createdAt',
  },
  {
    title: 'Video',
    field: 'duration',
  },
  {
    title: 'Action',
    type: 'action',
    noSort: true,
  },
];

export default function MeetingsList({
  classes,
  searchInput,
  setSearchInput,
  tab,
  destinationMeetingId,
  prevSectionId,
  deleted,
}) {
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [page, setPage] = useState(1);
  const [debouncedSearchInput] = useDebounce(searchInput, 500);
  const [isMeetingDetailsModalVisible, setIsMeetingDetailsModal] =
    useState(false);
  const [sortField, setSortField] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const [isSummaryModalVisible, setIsSummaryModalVisible] = useState(false);
  const [currentContentTitle, setCurrentContentTitle] = useState(null);
  const [currentMeetingId, setCurrentMeetingId] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();
  const processing = useMeetingProcessing();

  const { showConfirmActionModal, notifySuccess } = useContext(UiContext);

  const itemsRange = isMobile ? mobileItemsRange : 5;

  const { data: meetings, refetch } = useQuery(
    [
      'meetings',
      page,
      debouncedSearchInput,
      itemsRange,
      tab,
      sortField,
      sortOrder,
      deleted,
    ],
    () =>
      MeetingService.getMeetings({
        page,
        search: debouncedSearchInput,
        limit: itemsRange,
        type: tab,
        sortField,
        sortOrder,
        deleted,
      }),
    {
      keepPreviousData: true,
      refetchInterval: isProcessing ? 10000 : false,
      onSuccess: (data) => {
        if (data?.some((m) => m.status === 'in progress')) {
          setIsProcessing(true);
        } else {
          setIsProcessing(false);
        }
      },
    }
  );

  // TODO: REMOVE
  // Refetch meeting on processing end and notify user
  useEffect(() => {
    if (processing.step === 'finishing') {
      refetch();

      const processedMeeting = meetings.find(
        (meeting) => meeting.id === processing.meetingId
      );
      const isSavedSectionMeeting = processedMeeting?.type === 'saved';

      if (!isSavedSectionMeeting && processedMeeting) {
        showConfirmActionModal({
          message: 'Your video is ready to share.',
          title: '... and done!',
          noButtons: true,
        });
      }
    }
  }, [meetings, processing, refetch, showConfirmActionModal, tab]);

  // Refetch meeting on processing end and notify user
  useMeetingReady((meetingId) => {
    console.log('PROCESSING READY', meetingId);
    refetch();

    const processedMeeting = meetings.find(
      (meeting) => meeting.id === meetingId
    );
    const isSavedSectionMeeting = processedMeeting?.type === 'saved';

    if (!isSavedSectionMeeting && processedMeeting) {
      showConfirmActionModal({
        message: 'Your video is ready to share.',
        title: '... and done!',
        noButtons: true,
      });
    }
  });

  const { data: user } = useQuery('me', UserService.getMe);

  const { mutate: toggleMeetingTrashStatusMutation } = useMutation(
    MeetingService.toggleMeetingTrashStatus,
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const { mutate: updateMeetingMutation } = useMutation(
    MeetingService.updateMeeting,
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const { mutate: importMeetingMutation } = useMutation(
    MeetingService.importMeeting,
    {
      onSuccess: () => {
        navigate(`/my-videos/${destinationMeetingId}/edit`);
      },
    }
  );

  const switchPage = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleDetails = useCallback((meeting) => {
    setSelectedMeeting(meeting);
    setIsMeetingDetailsModal(true);
  }, []);

  const handleDelete = useCallback(
    (meetingId) => {
      showConfirmActionModal({
        message: 'Are you sure you want to delete this video?',
        onConfirm: () => toggleMeetingTrashStatusMutation(meetingId),
      });
    },
    [showConfirmActionModal, toggleMeetingTrashStatusMutation]
  );

  const handleCopyFolderLink = useCallback(
    async (folderId, meetingId) => {
      try {
        if (folderId) {
          copyFolderLink(folderId, meetingId);
        } else {
          const { link } = await MeetingService.shareMeetingLink({
            meetingId,
            security: 'public',
          });

          navigator.clipboard.writeText(link);
        }

        notifySuccess(`Link copied to clipboard`);
      } catch (error) {
        console.log(error);
      }
    },
    [notifySuccess]
  );

  const handleUpdateMeeting = useCallback(
    (meetingId, title) => {
      updateMeetingMutation({
        meetingId,
        title,
      });
    },
    [updateMeetingMutation]
  );

  const handleMeetingClick = useCallback(
    (meetingId) => {
      if (!destinationMeetingId) {
        return;
      }

      importMeetingMutation({
        sourceMeetingId: meetingId,
        destinationMeetingId,
        prevSectionId,
      });
    },
    [destinationMeetingId, importMeetingMutation, prevSectionId]
  );

  const handlePlay = useCallback(
    (meetingId) => {
      navigate(`/my-videos/${meetingId}`);
    },
    [navigate]
  );

  useEffect(() => {
    setPage(1);
    refetch();
  }, [debouncedSearchInput, refetch]);

  useEffect(() => {
    if (meetings?.rows) {
      if (meetings.rows.length === 0 && page !== 1) {
        setPage((prevPage) => prevPage - 1);
      }
    }
  }, [meetings?.rows, page]);

  const isOrganizationOwner = user?.orgRole?.access === ORG_ROLES.OWNER;

  return (
    <>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead>
            <tr>
              {ths.map((th) => (
                <Th
                  key={th.field}
                  classes={classes}
                  sortField={sortField}
                  sortOrder={sortOrder}
                  setSortField={setSortField}
                  setSortOrder={setSortOrder}
                  field={th.field}
                  title={th.title}
                  searchInput={searchInput}
                  setSearchInput={setSearchInput}
                  noSort={th.noSort}
                />
              ))}
            </tr>
          </thead>
          {meetings?.length ? (
            <tbody>
              {meetings.map((meeting) => {
                return (
                  <Row
                    key={meeting.id}
                    classes={classes}
                    meeting={meeting}
                    isMobile={isMobile}
                    isOrganizationOwner={isOrganizationOwner}
                    tab={tab}
                    user={user}
                    handleUpdateMeeting={handleUpdateMeeting}
                    handlePlay={handlePlay}
                    handleCopyFolderLink={handleCopyFolderLink}
                    handleMeetingClick={handleMeetingClick}
                    destinationMeetingId={destinationMeetingId}
                    handleDetails={handleDetails}
                    handleDelete={handleDelete}
                    setCurrentContentTitle={setCurrentContentTitle}
                    setCurrentMeetingId={setCurrentMeetingId}
                    setIsSummaryModalVisible={setIsSummaryModalVisible}
                  />
                );
              })}
            </tbody>
          ) : (
            <p className={classes.noMeetings}>
              {debouncedSearchInput.length ? (
                "Oops! We can't find any videos with that name."
              ) : (
                <EmptyFolderMessage />
              )}
            </p>
          )}
        </table>
      </div>

      {+meetings?.[0]?.count > itemsRange && (
        <div className={classes.paginationContainer}>
          <Pagination
            itemClass={classes.button}
            innerClass={classes.buttonsList}
            activeClass={classes.active}
            disabledClass={classes.disabled}
            activePage={page}
            itemsCountPerPage={itemsRange}
            totalItemsCount={+meetings?.[0]?.count || 0}
            pageRangeDisplayed={itemsRange}
            prevPageText="Prev"
            nextPageText="Next"
            onChange={switchPage}
          />
        </div>
      )}

      <CreateMeetingMobileButton />

      <MeetingDetailsModal
        meeting={selectedMeeting}
        show={isMeetingDetailsModalVisible}
        handleClose={() => setIsMeetingDetailsModal(false)}
      />
      <SummaryViewerReportModal
        show={isSummaryModalVisible}
        handleClose={() => setIsSummaryModalVisible(false)}
        contentTitle={currentContentTitle}
        contentType="meeting"
        id={currentMeetingId}
      />
    </>
  );
}
