import React from 'react';

import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import classes from './styles.module.scss';
import { ORG_ROLES } from '../../constants/main';
import UserService from '../../services/UserService';

export default function CreateMeetingMobileButton({ style, onClick }) {
  const navigate = useNavigate();

  const { data: user } = useQuery('me', UserService.getMe);

  if (user?.orgRole?.access === ORG_ROLES.VIEWER) {
    return null;
  }

  return (
    <button
      onClick={() => {
        if (onClick) {
          onClick();
        } else {
          navigate('/create-video');
        }
      }}
      className={classes.UploadButton}
      type="button"
      style={style}
    >
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.418 7.41798H10.582V1.58202C10.582 0.7083 9.87375 0 9 0C8.12625 0 7.41798 0.7083 7.41798 1.58202V7.41798H1.58202C0.7083 7.41798 0 8.12625 0 9C0 9.87375 0.7083 10.582 1.58202 10.582H7.41798V16.418C7.41798 17.2917 8.12625 18 9 18C9.87375 18 10.582 17.2917 10.582 16.418V10.582H16.418C17.2917 10.582 18 9.87375 18 9C18 8.12625 17.2917 7.41798 16.418 7.41798Z"
          fill="white"
        />
      </svg>
    </button>
  );
}
