import React from 'react';

import SearchBar from '../../SearchBar';
import UserBlock from '../UserBlock';

import classes from './styles.module.scss';

export default function DesktopHeader({
  hasUserBlock,
  hasSearch,
  searchInput,
  setSearchInput,
  searchDisabled,
}) {
  return (
    <header className={classes.DesktopHeader}>
      {/* <img src={logo} alt="Logo" className={classes.logo} /> */}
      {hasSearch && (
        <div className={classes.searchBarContainer}>
          <SearchBar
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            disabled={searchDisabled}
          />
        </div>
      )}
      {hasUserBlock && <UserBlock />}
    </header>
  );
}
