/* eslint-disable no-param-reassign */
import { useState, useEffect, useCallback } from 'react';

import { useUiContext } from '../context/UiContext';

export default function usePreviewSections({
  data,
  videoRef,
  animateProgressBar,
  setQuestionToShow,
  setIsQuestionModalVisible,
  setPreviousShownQuestions,
  setPreviousShownNote,
  setIsPlaying,
}) {
  const [sectionsStartPoints, setSectionsStartPoints] = useState([]);
  const [currentSectionOrder, setCurrentSectionOrder] = useState(0);

  const { showViewerNotesModal } = useUiContext();

  const handleRewindToSection = useCallback(
    (section, order) => {
      if (section.Question) {
        setQuestionToShow(section.Question);
        setIsQuestionModalVisible(true);
        setIsPlaying(false);
        videoRef.current.pause();
      } else {
        setPreviousShownQuestions([]);
      }

      if (section.type === 'note') {
        showViewerNotesModal(section.text, () => {
          videoRef.current.play();
          setIsPlaying(true);
        });
        setPreviousShownNote(section);
      } else {
        setPreviousShownNote({});
      }

      const sectionTimeCode = sectionsStartPoints[order] - section.duration;

      if (order === 0) {
        videoRef.current.currentTime = 0;
      } else {
        videoRef.current.currentTime = sectionTimeCode + 0.2;
      }

      if (section.type === 'audio' || section.type === 'video') {
        videoRef.current.play();
        setIsPlaying(true);
      }

      animateProgressBar();
    },
    [
      animateProgressBar,
      sectionsStartPoints,
      setIsPlaying,
      setIsQuestionModalVisible,
      setPreviousShownNote,
      setPreviousShownQuestions,
      setQuestionToShow,
      showViewerNotesModal,
      videoRef,
    ]
  );

  useEffect(() => {
    const preparedSectionsStartPoints = [];
    let currentSectionStartPoint = 0;

    data?.Sections.forEach((section) => {
      if (section.Question || section.type === 'note') {
        if (section.Question) {
          preparedSectionsStartPoints.push(section.Question.timestamp);
        } else {
          preparedSectionsStartPoints.push(section.timestamp);
        }
        return;
      }

      currentSectionStartPoint += section.duration;
      preparedSectionsStartPoints.push(currentSectionStartPoint);
    });

    setSectionsStartPoints(preparedSectionsStartPoints);
  }, [data]);

  useEffect(() => {
    setCurrentSectionOrder(
      sectionsStartPoints.findIndex(
        (time) => videoRef.current?.currentTime <= time
      )
    );
  }, [sectionsStartPoints, videoRef, videoRef.current?.currentTime]);

  return {
    handleRewindToSection,
    currentSectionOrder,
  };
}
