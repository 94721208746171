import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';

import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import classes from './styles.module.scss';
import searchIcon from '../../assets/images/my-meetings/search.svg';
import searchWhiteIcon from '../../assets/images/my-meetings/search-white.svg';

export default function SearchBar({ searchInput, setSearchInput, disabled }) {
  const isMobile = useCheckMobileScreen();

  const [isActive] = useState(true);

  const inputRef = useRef();
  const toggleRef = useRef();

  const handleChangeSearchInput = (event) => {
    if (disabled) {
      return;
    }
    setSearchInput(event.target.value);
  };

  useEffect(() => {
    if (isMobile || disabled) {
      return;
    }

    if (!isActive) {
      setSearchInput?.('');
    } else {
      inputRef.current.focus();
    }
  }, [disabled, isActive, isMobile, setSearchInput]);

  return (
    <div
      className={classNames(classes.SearchBar, { [classes.active]: isActive })}
    >
      <input
        disabled={disabled}
        ref={inputRef}
        type="text"
        value={searchInput}
        onChange={handleChangeSearchInput}
        placeholder="Search files..."
      />
      <div ref={toggleRef} type="button" className={classes.icon}>
        <img src={isMobile ? searchWhiteIcon : searchIcon} alt="Search" />
      </div>
    </div>
  );
}
