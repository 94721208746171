import React from 'react';

import classNames from 'classnames';
import classes from './styles.module.scss';
import arrow from '../../../assets/images/auth/arrow.svg';
import checkmark from '../../../assets/images/auth/checkmark.svg';

export default function ConfirmButton({
  isDisabled,
  onClick,
  withArrow,
  style,
  width,
}) {
  return (
    <button
      type="submit"
      disabled={isDisabled}
      className={classNames(classes.ConfirmButton, {
        [classes.withWidth]: width,
      })}
      onClick={onClick}
      style={{ ...style, width }}
    >
      {withArrow ? (
        <img src={arrow} alt="Arrow" />
      ) : (
        <img src={checkmark} alt="Checkmark" />
      )}
    </button>
  );
}
