/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useRef, useCallback, memo } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import WaveForm from '../../MediaContainer/WaveForm';
// import VideoProgress from '../VideoProgress';
import VideoPlayerOverlay from '../../VideoPlayerOverlay';

import classes from './styles.module.scss';

/* function isIOS() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
} */

function MeetingVideoPlayer({
  currentAudioSectionWithoutBackground,
  currentAudioSectionProgress,
  meeting,
  waveFormContainerRef,
  videoRef,
  isDraggingProgressBar,
  progressBarRef,
  // progressBarHandler,
  // setIsDraggingProgressBar,
  videoDuration,
  setPreviousShownQuestions,
  setPreviousShownNote,
  animateProgressBar,
  stopAnimation,
  // videoProgress,
  // isSectionsVisible,
  //  setIsSectionsVisible,
  isPlaying,
  setIsPlaying,
  meetingVersion,
  confirmMeetingView,
  folderId,
  title,
  signedS3Url,
}) {
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [isViewConfirmed, setIsViewConfirmed] = useState(false);

  const meetingVideoPlayerRef = useRef();

  const navigate = useNavigate();
  const location = useLocation();

  const handleBackClick = useCallback(() => {
    const queryParams = new URLSearchParams(location.search);
    const isFromEmail = queryParams.get('fromEmail');

    if (isFromEmail && folderId) {
      navigate(`/my-library/${folderId}`);
    } else if (isFromEmail) {
      navigate('/my-videos');
    } else {
      navigate(-1);
    }
  }, [location.search, navigate, folderId]);

  // Reset last shown question if video has ended
  useEffect(() => {
    if (videoRef.current.currentTime === videoRef.current.duration) {
      setPreviousShownQuestions([]);
      setPreviousShownNote({});
      setIsPlaying(false);
    }
  }, [videoRef.current?.currentTime]);

  // Set video ready state
  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    const setReadyState = () => {
      setIsVideoReady(true);
    };

    // videoRef.current.addEventListener('canplay', setReadyState);
    videoRef.current.addEventListener('loadedmetadata', setReadyState);

    return () => {
      if (videoRef.current) {
        // videoRef.current.removeEventListener('canplay', setReadyState);

        videoRef.current.removeEventListener('loadedmetadata', setReadyState);
      }
    };
  }, [videoRef]);

  const playButtonHandler = () => {
    setIsPlaying(true);
    videoRef.current.play();
    animateProgressBar();

    if (!isViewConfirmed) {
      confirmMeetingView();
    }

    setIsViewConfirmed(true);
  };

  const pauseButtonHandler = () => {
    setIsPlaying(false);
    videoRef.current.pause();
    stopAnimation();
  };

  return (
    <div className={classes.MeetingVideoPlayer} ref={meetingVideoPlayerRef}>
      <div className={classes.controlsOverlay}>
        <VideoPlayerOverlay
          meetingTitle={meeting ? meeting.title : title}
          videoRef={videoRef}
          viewOnly
          videoDuration={videoDuration}
          progressBarRef={progressBarRef}
          isVideoReady={isVideoReady}
          videoSectionPlayerRef={videoRef}
          stopVideoPlayer={pauseButtonHandler}
          pauseVideoPlayer={pauseButtonHandler}
          startVideoPlayer={playButtonHandler}
          isPlaying={isPlaying}
          arePlayerButtonsVisible={isVideoReady}
          isDraggingProgressBar={isDraggingProgressBar}
          onBackClick={handleBackClick}
        />
      </div>
      {currentAudioSectionWithoutBackground && !meetingVersion && (
        <div className={classes.waveFormContainer} ref={waveFormContainerRef}>
          <WaveForm
            source={currentAudioSectionWithoutBackground.section.signedS3Url}
            containerRef={meetingVideoPlayerRef}
            fullHeight
            seek={currentAudioSectionProgress}
          />
        </div>
      )}
      <video
        muted
        playsInline
        src={`${meeting ? meeting.signedS3Url : signedS3Url}#t=0.001`}
        ref={videoRef}
        style={{ visibility: currentAudioSectionWithoutBackground && 'hidden' }}
      />
      <div
        className={classes.controlsContainer}
        style={{
          visibility: isVideoReady ? 'visible' : 'hidden',
        }}
      />
    </div>
  );
}

export default memo(MeetingVideoPlayer);
