import React, { useRef } from 'react';

import CreateMeetingMobileButton from '../CreateMeetingMobileButton';
import DropdownMenu from '../DropdownMenu';

import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import classes from './styles.module.scss';
// import imageIcon from '../../assets/images/create-meeting/image.svg';
import videoIcon from '../../assets/images/create-meeting/video.svg';
import fileIcon from '../../assets/images/create-meeting/file.svg';

export default function UploadMenu({
  createDefaultSectionName,
  setSectionTitle,
  setFile,
  setIsUpdateMeetingTitleModalVisible,
}) {
  const captureFileInputRef = useRef();
  const fileInputRef = useRef();

  const isMobile = useCheckMobileScreen();

  const start = (isCaptureEnabled) => {
    if (!isMobile) {
      return;
    }

    const title = createDefaultSectionName({
      placeholderType: 'video',
    });

    setSectionTitle(title);

    if (isCaptureEnabled) {
      captureFileInputRef.current.click();
    } else {
      fileInputRef.current.click();
    }
  };

  const options = [
    /*   {
      label: 'Photo Library',
      onClick: () => {
        start(false);
      },
      // eslint-disable-next-line jsx-a11y/img-redundant-alt
      icon: <img src={imageIcon} alt="Photo Library" />,
      disabled: false,
    }, */
    {
      label: 'Take Video',
      onClick: () => {
        start(true);
      },
      icon: <img src={videoIcon} alt="Take Video" />,
      disabled: false,
    },
    {
      label: 'Choose File',
      onClick: () => {
        start(false);
      },
      icon: <img src={fileIcon} alt="Choose File" />,
      disabled: false,
    },
  ];

  const inputAttributes = {
    type: 'file',
    style: { display: 'none' },
    onChange: (event) => {
      setFile(event.target.files[0]);
      setIsUpdateMeetingTitleModalVisible(true);
    },
    //  ref: fileInputRef,
    accept:
      'video/mp4, video/quicktime, video/x-ms-wmv, video/x-msvideo, video/webm',
    // ...(isCaptureEnabled && { capture: 'user' }),
  };

  return (
    <div className={classes.UploadMenu}>
      <DropdownMenu
        options={options}
        menuStyle={{ right: '50%', bottom: -78, transform: 'translateX(50%)' }}
      >
        <div className={classes.outerButtonContainer}>
          <div className={classes.innerButtonContainer}>
            <CreateMeetingMobileButton
              style={{ position: 'static', zIndex: 0 }}
              onClick={() => {}}
            />
          </div>
        </div>
      </DropdownMenu>
      <span>Upload from my device</span>
      <input {...inputAttributes} ref={fileInputRef} />
      <input {...inputAttributes} ref={captureFileInputRef} capture="user" />
    </div>
  );
}
