import React, { useState } from 'react';

import Answer from './Answer';

import classes from './styles.module.scss';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import chevronIcon from '../../assets/images/view-meeting/chevron.svg';

export default function Question({ question }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const isMobile = useCheckMobileScreen();

  return (
    <div className={classes.Question}>
      <div className={classes.questionContainer}>
        <p>{question.text}</p>
        <span
          className={classes.responseCount}
          onClick={() => setIsExpanded((prevState) => !prevState)}
        >
          {question.Answers?.length}{' '}
          {question.Answers?.length === 1 ? 'response' : 'responses'}{' '}
          {isMobile && question.Answers?.length ? (
            <img
              style={{
                transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
              src={chevronIcon}
              alt="Chevron"
            />
          ) : null}
        </span>
      </div>
      {question.Answers?.length && (isExpanded || !isMobile) ? (
        <div className={classes.answers}>
          {question.Answers.map((answer) => (
            <Answer key={answer.id} answer={answer} />
          ))}
        </div>
      ) : null}
    </div>
  );
}
