/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';

import classNames from 'classnames';

import playIcon from '../../../../assets/images/video-player/play.svg';
import pauseIcon from '../../../../assets/images/video-player/pause.svg';
// import stopIcon from '../../../../assets/images/player-buttons/stop.svg';
import rewindIcon from '../../../../assets/images/video-player/rewind.svg';
import forwardIcon from '../../../../assets/images/video-player/forward.svg';
import volumeIcon from '../../../../assets/images/video-player/volume.svg';
import mutedVolumeIcon from '../../../../assets/images/video-player/mutedVolume.svg';
import classes from './styles.module.scss';

export default function Buttons({
  isRecording,
  stopVideoPlayer,
  startTimer,
  startVideoPlayer,
  pauseVideoPlayer,
  createSectionMode,
  isPlaying,
  isVideoReady,
  arePlayerButtonsVisible,
  videoRef,
  animateProgressBar,
}) {
  const [isVideoMuted, setIsVideoMuted] = useState(false);

  const isChrome =
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    setIsVideoMuted(videoRef.current.muted);
  }, [videoRef]);

  const startRecording = () => {
    if (!isRecording) {
      startTimer();
    }
  };

  const stopRecording = () => {
    if (isRecording) {
      stopVideoPlayer();
    }
  };

  const toggleMuteAudio = () => {
    videoRef.current.muted = !videoRef.current.muted;
    setIsVideoMuted(videoRef.current.muted);
    console.log('isVideoMuted', isVideoMuted);
  };

  const rewindButtonHandler = () => {
    videoRef.current.currentTime -= 15;
  };

  const forwardButtonHandler = () => {
    videoRef.current.currentTime += 30;
  };

  return (
    <div
      className={classNames(classes.Buttons, {
        [classes.chrome]: isChrome,
      })}
      style={{
        visibility:
          (isVideoReady || createSectionMode) && arePlayerButtonsVisible
            ? 'visible'
            : 'hidden',
      }}
    >
      <ul>
        {createSectionMode ? (
          <>
            <li>
              <div
                role="button"
                className={classes.recordControl}
                tabIndex={0}
                onClick={startRecording}
              >
                <div className={classes.recordButton} />
                <span>Start Record</span>
              </div>
            </li>
            <li>
              <div
                role="button"
                tabIndex={0}
                onClick={stopRecording}
                className={classes.recordControl}
              >
                <div className={classes.stopButton} />
                <span>Stop Record</span>
              </div>
            </li>
          </>
        ) : (
          <>
            {isPlaying ? (
              <li>
                <button
                  type="button"
                  onClick={(event) => {
                    pauseVideoPlayer(event);
                    event.stopPropagation();
                  }}
                >
                  <img src={pauseIcon} alt="pause" />
                </button>
              </li>
            ) : (
              <li>
                <button
                  type="button"
                  onClick={(event) => {
                    startVideoPlayer(event);
                    event.stopPropagation();
                    animateProgressBar();
                  }}
                >
                  <img src={playIcon} alt="play" />
                </button>
              </li>
            )}
            <li>
              <button
                type="button"
                onClick={(event) => {
                  rewindButtonHandler();
                  event.stopPropagation();
                }}
              >
                <img src={rewindIcon} alt="Rewind" />
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={(event) => {
                  forwardButtonHandler();
                  event.stopPropagation();
                }}
              >
                <img src={forwardIcon} alt="Forward" />
              </button>
            </li>
            <li className={classes.mute}>
              <button
                type="button"
                onClick={(event) => {
                  toggleMuteAudio();
                  event.stopPropagation();
                }}
              >
                <img
                  src={isVideoMuted ? mutedVolumeIcon : volumeIcon}
                  alt="Mute"
                />
              </button>
            </li>
            {/*  <li>
              <button type="button" onClick={stopVideoPlayer}>
                <img src={stopIcon} alt="stop" />
              </button>
            </li> */}
          </>
        )}
      </ul>
    </div>
  );
}
