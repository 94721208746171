import React from 'react';

import { useLocation, NavLink } from 'react-router-dom';

import classNames from 'classnames';
import myLibraryIcon from '../../../assets/images/nav/my-library.svg';
import myVideosIcon from '../../../assets/images/nav/my-videos.svg';
import settingsIcon from '../../../assets/images/nav/profile.svg';
import trashIcon from '../../../assets/images/nav/trash-bin.svg';
import myLibraryActiveIcon from '../../../assets/images/nav/my-library-active.svg';
import trashActiveIcon from '../../../assets/images/nav/trash-bin-active.svg';
import profileActiveIcon from '../../../assets/images/nav/profile-active.svg';
import myVideosActiveIcon from '../../../assets/images/nav/my-videos-active.svg';
import { useUiStore } from '../../../store';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';

import classes from './styles.module.scss';

const links = [
  {
    title: 'My library',
    path: '/my-library',
    icon: myLibraryIcon,
    activeIcon: myLibraryActiveIcon,
  },
  {
    title: 'My Videos',
    path: '/my-videos',
    icon: myVideosIcon,
    activeIcon: myVideosActiveIcon,
  },
  {
    title: 'Settings',
    path: '/profile',
    icon: settingsIcon,
    activeIcon: profileActiveIcon,
  },
  {
    title: 'Trash',
    path: '/trash-bin',
    icon: trashIcon,
    activeIcon: trashActiveIcon,
  },
];

export default function NavBar() {
  const location = useLocation();
  const currentPath = location.pathname;

  const isMobile = useCheckMobileScreen();

  const isSidebarCollapsed = useUiStore((state) => state.isSidebarCollapsed);

  return (
    <ul
      className={classNames(classes.NavBar, {
        [classes.collapsed]: isSidebarCollapsed && !isMobile,
      })}
    >
      {links.map(({ title, path, icon, activeIcon }) => {
        const isActiveLink = currentPath.startsWith(path);
        return (
          <NavLink
            key={path}
            to={path}
            className={({ isActive }) => (isActive ? classes.active : '')}
          >
            <li>
              <div
                className={classNames(classes.iconContainer, [
                  classes[title.split(' ').join('')],
                ])}
              >
                <img src={isActiveLink ? activeIcon : icon} alt={title} />
              </div>
              <span className={classes.title}>{title}</span>
            </li>
          </NavLink>
        );
      })}
    </ul>
  );
}
