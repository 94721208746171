/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef, useContext, memo } from 'react';

import { useSearchParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import classNames from 'classnames';

import Question from '../../Question';
import ReplyBox from '../../ReplyBox';
import Comment from '../../Comment';
import Switch from '../../Switch';

import { UiContext } from '../../../context/UiContext';
import CommentService from '../../../services/CommentService';
import MeetingService from '../../../services/MeetingService';
import classes from './styles.module.scss';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';

function Comments({
  commentsSource,
  questions,
  user,
  refetchMeeting,
  videoRef,
  id,
  setIsSignUpPopupVisible,
  rewindVideoByComment,
  isAuthenticated,
  shouldNotify,
  refetchComments,
}) {
  const [commentText, setCommentText] = useState('');
  const [commentsMode, setCommentsMode] = useState('engagements');
  const [areNotificationsEnabled, setAreNotificationsEnabled] = useState(null);

  const textareaRef = useRef();

  const { notifyError } = useContext(UiContext);

  const [searchParams] = useSearchParams();

  const isMobile = useCheckMobileScreen();

  const { mutate: createCommentMutation } = useMutation(
    CommentService.createComment,
    {
      onSuccess: () => {
        textareaRef.current.textContent = '';
        refetchComments();
      },
      onError: (error) => {
        console.log(error);
        // notifyError(error?.response?.data?.message);
      },
    }
  );

  const { mutate: toggleNotifications } = useMutation(
    MeetingService.toggleMeetingNotifications,
    {
      onSuccess: () => {
        refetchMeeting();
      },
      onError: (error) => {
        console.log(error);
        notifyError(error?.response?.data?.message);
      },
    }
  );

  useEffect(() => {
    if (shouldNotify !== undefined) {
      setAreNotificationsEnabled(shouldNotify);
    }
  }, [shouldNotify]);

  useEffect(() => {
    if (
      shouldNotify !== areNotificationsEnabled &&
      shouldNotify !== undefined &&
      areNotificationsEnabled !== null
    ) {
      toggleNotifications(id);
    }
  }, [areNotificationsEnabled, id, toggleNotifications, shouldNotify]);

  const createComment = () => {
    if (commentText.trim()) {
      createCommentMutation({
        textContent: commentText,
        meetingId: id,
        meetingTime: videoRef.current?.currentTime,
      });
    }
  };

  useEffect(() => {
    if (searchParams.get('responses')) {
      setCommentsMode('responses');
    }
  }, [searchParams, setCommentsMode]);

  return (
    <div className={classes.Comments}>
      <div className={classes.container}>
        <header className={classes.header}>
          <div className={classes.tabs}>
            <div
              className={classNames(
                classes.tab,
                classes.engagements,
                commentsMode === 'engagements' && classes.active
              )}
              onClick={() => setCommentsMode('engagements')}
            >
              <span>Comments</span>
            </div>
            <div
              className={classNames(
                classes.tab,
                classes.responses,
                commentsMode === 'responses' && classes.active
              )}
              onClick={() => setCommentsMode('responses')}
            >
              <span>Question Responses</span>
            </div>
          </div>
          <div className={classes.switchContainer}>
            <Switch
              onClick={() =>
                setAreNotificationsEnabled((prevState) => !prevState)
              }
              label="Notify me"
              width={isMobile ? 60 : 88}
              withOnLabel
              tooltipText="Notify me when people interact with this video"
              isChecked={areNotificationsEnabled}
            />
          </div>
        </header>

        {commentsMode === 'engagements' && (
          <ReplyBox
            textareaRef={textareaRef}
            user={user}
            commentText={commentText}
            setCommentText={setCommentText}
            createComment={createComment}
            setIsSignUpPopupVisible={setIsSignUpPopupVisible}
          />
        )}
        {commentsMode === 'engagements' && (
          <div className={classes.commentsList}>
            {commentsSource?.Comments?.map((comment) => (
              <Comment
                key={comment.id}
                comment={comment}
                createCommentMutation={createCommentMutation}
                rewindVideoByComment={rewindVideoByComment}
                user={user}
                refetch={refetchComments}
                meetingId={id}
                isAuthenticated={isAuthenticated}
                setIsSignUpPopupVisible={setIsSignUpPopupVisible}
              />
            ))}
          </div>
        )}
        {commentsMode === 'responses' && (
          <>
            {questions.length ? (
              <div className={classes.questionsList}>
                {questions?.map((question) => (
                  <Question question={question} key={question.id} />
                ))}
              </div>
            ) : (
              <p className={classes.noQuestions}>
                There are no questions in this video
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default memo(Comments);
