import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import SearchBar from '../../SearchBar';
import UserBlock from '../UserBlock';
import NavBar from '../../../containers/Layout/NavBar';

import classes from './styles.module.scss';
import logo from '../../../assets/images/snapsop2.png';
import burgerIcon from '../../../assets/images/nav/burger.svg';
import closeIcon from '../../../assets/images/nav/close.svg';

export default function MobileHeader({
  hasSearch,
  searchInput,
  setSearchInput,
  searchDisabled,
}) {
  const [isNavBarOpen, setIsNavBarOpen] = useState(false);

  // Prevent scrolling when the mobile nav bar is open
  useEffect(() => {
    if (isNavBarOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isNavBarOpen]);

  return (
    <div className={classes.MobileHeader}>
      <div className={classes.row}>
        <button
          type="button"
          className={classes.burgerButton}
          onClick={() => setIsNavBarOpen((prevState) => !prevState)}
        >
          <img src={isNavBarOpen ? closeIcon : burgerIcon} alt="Burger" />
        </button>
        {isNavBarOpen ? (
          <h2 className={classes.mobileMenusTitle}>Mobile Menus</h2>
        ) : (
          <Link to="/">
            <img src={logo} alt="Logo" className={classes.logo} />
          </Link>
        )}
        <div>
          <UserBlock />
        </div>
      </div>
      <div className={classes.row}>
        {hasSearch && !isNavBarOpen && (
          <div className={classes.searchBarContainer}>
            <SearchBar
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              disabled={searchDisabled}
            />
          </div>
        )}
      </div>
      {isNavBarOpen && (
        <nav className={classes.mobileNavBar}>
          <NavBar />
        </nav>
      )}
    </div>
  );
}
