/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef, useState } from 'react';

import classNames from 'classnames';
import threeDots from '../../assets/images/my-library/threeDots.svg';

import useOnClickOutside from '../../hooks/useOnClickOutside';
import classes from './styles.module.scss';

export default function DropdownMenu({
  options,
  isTrashBin,
  children,
  menuStyle,
}) {
  const [isActionsMenuVisible, setIsActionsMenuVisible] = useState(false);

  const menuRef = useRef(null);
  const actionsRef = useRef(null);

  useOnClickOutside(menuRef, () => setIsActionsMenuVisible(false), actionsRef);

  return (
    <div
      ref={actionsRef}
      className={classNames(classes.DropdownMenu, {
        [classes.trashBin]: isTrashBin,
      })}
    >
      <button
        onClick={(e) => {
          e.stopPropagation();
          setIsActionsMenuVisible((prevState) => !prevState);
        }}
        type="button"
        className={classes.button}
      >
        {children || <img src={threeDots} alt="Actions" />}
      </button>

      <div
        ref={menuRef}
        className={classNames(classes.menu, {
          [classes.isVisible]: isActionsMenuVisible,
        })}
        style={menuStyle}
      >
        <ul>
          {options.map((option) => (
            <li key={option.label} onClick={(e) => e.stopPropagation()}>
              <button
                className={classes.button}
                onClick={() => {
                  option.onClick();
                  setIsActionsMenuVisible(false);
                }}
                disabled={option.disabled}
                type="button"
              >
                {option.label}
                {option.icon}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
