/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  useLayoutEffect,
} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

import FileUploadProgressModal from '../../modals/FileUploadProgressModal';
import Layout from '../../containers/Layout';
import Breadcrumbs from '../../components/Breadcrumbs';
import CreateMeetingButtons from '../../components/CreateMeetingButtons';
import UploadMenu from '../../components/UploadMenu';

// import updateTitleIcon from '../../assets/images/my-meetings/edit.svg';
import ShareModal from '../../modals/ShareModal';
import CopyLinkModal from '../../modals/CopyLinkModal';
import SelectTimeZoneModal from '../../modals/SelectTimeZoneModal';
import CreateMeetingFooter from '../../components/CreateMeetingFooter';
import MediaContainer from '../../components/MediaContainer';
import Navigation from '../../components/Navigation';
import { UiContext } from '../../context/UiContext';
import MeetingService from '../../services/MeetingService';
// import useResizeObserver from '../../hooks/useResizeObserver';
import classes from './styles.module.scss';
import { MEETING_STATUSES, ORG_ROLES } from '../../constants/main';
import UserService from '../../services/UserService';
import localTimeZone from '../../helpers/localTimeZone';
import UpdateMeetingTitleModal from '../../modals/UpdateMeetingTitleModal';
import PublishMeetingModal from '../../modals/PublishMeetingModal';
import SelectShareOptionModal from '../../modals/SelectShareOptionModal';
import RequestReviewModal from '../../modals/RequestReviewModal';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import AlertModal from '../../modals/AlertModal';
import { SocketContext } from '../../context';
// import undoIcon from '../../assets/images/create-meeting/undo.svg';
// import undoDisabledIcon from '../../assets/images/create-meeting/undoDisabled.svg';
import useMeetingQueries from './useMeetingQueries';
import { useCreateMeetingStore, useUiStore } from '../../store';

let chunks;
let mediaStreams = [];
let noContentWarningTimer;

export default function CreateOrUpdateMeetingPage() {
  const [
    isUpdateMeetingTitleModalVisible,
    setIsUpdateMeetingTitleModalVisible,
  ] = useState(false);
  const [isCameraEnabled, setIsCameraEnabled] = useState(false);
  const [isScreenEnabled, setIsScreenEnabled] = useState(false);

  const [isSaveSectionPopupVisible, setIsSaveSectionPopupVisible] =
    useState(false);

  const [currentSectionsPage, setCurrentSectionsPage] = useState(0);
  //  const [sectionsPageCount, setSectionPageCount] = useState(0);
  const [isShareMeetingModalVisible, setIsShareMeetingModalVisible] =
    useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [fetchedQuestionData, setFetchedQuestionData] = useState(null);
  const [isAssignSectionFormVisible, setIsAssignSectionFormVisible] =
    useState(false);
  const [cameraHasStopped, setCameraHasStopped] = useState(false);
  // const [screenHasStopped, setScreenHasStopped] = useState(false);
  const [isNoTitleWarningVisible, setIsNoTitleWarningVisible] = useState(false);
  const [isSectionsCompletePopupVisible, setIsSectionsCompletePopupVisible] =
    useState(false);
  const [isAudioEnabled, setIsAudioEnabled] = useState(false);
  const [isScreenRecordReminderVisible, setIsScreenRecordReminderVisible] =
    useState(false);
  const [isShareSuccessPopupVisible, setIsShareSuccessPopupVisible] =
    useState(false);
  const [isPreparingPreview, setIsPreparingPreview] = useState(false);
  const [isNoContentWarningPopupVisible, setIsNoContentWarningPopupVisible] =
    useState(false);
  const [sectionTitle, setSectionTitle] = useState('');
  const [isSelectTimeZoneModalVisible, setIsSelectTimeZoneModalVisible] =
    useState(false);
  const [timeZoneAssignment, setTimeZoneAssignment] = useState('');
  const [isSectionCreationStopped, setIsSectionCreationStopped] =
    useState(false);
  const [selectedTimeZone, setSelectedTimeZone] = useState({
    schedule: {
      label: '',
      value: localTimeZone,
    },
    expiration: {
      label: '',
      value: localTimeZone,
    },
  });
  const [isCopyLinkModalVisible, setIsCopyLinkModalVisible] = useState(false);
  const [sharedMeetingAccess, setSharedMeetingAccess] = useState({
    link: '',
    passcode: '',
    security: '',
  });
  const [isPublishMeetingModalVisible, setIsPublishMeetingModalVisible] =
    useState(false);
  const [isSelectShareOptionModalVisible, setIsSelectShareOptionModalVisible] =
    useState(false);
  const [isRequestReviewModalVisible, setIsRequestReviewModalVisible] =
    useState(false);
  const [isSectionSavedModalVisible, setIsSectionSavedModalVisible] =
    useState(false);
  const [processingProgress, setProcessingProgress] = useState({
    sectionId: '',
    percentage: 0,
  });
  const [
    isFileUploadProgressModalVisible,
    setIsFileUploadProgressModalVisible,
  ] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [prevSectionId, setPrevSectionId] = useState(null);
  const [hasSections, setHasSections] = useState(false);

  const videoSectionPlayerRef = useRef();
  const streamPreviewMediaPlayerRef = useRef();
  const mediaRecorderRef = useRef();
  const innerContainerRef = useRef();
  const streamRef = useRef();
  const videoPlayerRef = useRef();
  const videoPlayerContainerRef = useRef();
  const sectionCountRef = useRef(0);

  const navigate = useNavigate();
  const { socket } = useContext(SocketContext);

  // const { width } = useResizeObserver(innerContainerRef);
  const isMobile = useCheckMobileScreen();

  const { notifyError, showConfirmActionModal } = useContext(UiContext);

  const breadcrumbsOptions = useUiStore((state) => state.breadcrumbsOptions);

  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const isShare = searchParams.get('share');
  const areSectionsFetchedAlready = useRef(false);
  const folderId = searchParams.get('folderId');

  const setIsSectionAutoScrollDisabled = useCreateMeetingStore(
    (state) => state.setIsSectionAutoScrollDisabled
  );

  const {
    fetchedSections,
    refetch,
    fetchedMeeting,
    createSection,
    setMeetingTitle,
    meetingTitle,
    updateMeeting,
    isPublishMeetingPopupVisible,
    isQuestionFormVisible,
    setIsQuestionFormVisible,
    question,
    setQuestion,
    file,
    setFile,
    audioImage,
    setAudioImage,
    firstPlaceholderTitle,
    setFirstPlaceholderTitle,
    meetingId,
    setMeetingId,
    deleteSection,
    //  undo,
    //  canUndo,
    setSections,
    setCurrentSectionId,
    currentSectionId,
    setVideoPlayerSource,
    sections,
    videoPlayerSource,
    isConfirmSectionRemovalVisible,
    setIsConfirmSectionRemovalVisible,
    changeSectionPosition,
    updateSectionTitle,
    updateTextSection,
    updateMeetingTitle,
    updateQuestionSection,
    isNotAllowedToDeleteSectionMessageVisible,
    setIsNotAllowedToDeleteSectionMessageVisible,
  } = useMeetingQueries({ meetingId: id, prevSectionId, setPrevSectionId });

  useEffect(() => {
    if (!hasSections && sections && sections.length > 0) {
      setHasSections(true);
    }
  }, [sections, hasSections]);

  // Disable body scroll on this page
  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, []);

  useEffect(() => {
    if (isShare === 'true') {
      setIsSelectShareOptionModalVisible(true);
    }
  }, [isShare]);

  useEffect(() => {
    if (isMobile) {
      return;
    }

    if (id) {
      setMeetingId(id);
    } else {
      setIsUpdateMeetingTitleModalVisible(true);
    }
  }, [id, isMobile, setMeetingId]);

  const { data: user } = useQuery('me', UserService.getMe);

  useEffect(() => {
    if (fetchedMeeting) {
      setMeetingId(fetchedMeeting.id);
    }
  }, [fetchedMeeting, setMeetingId]);

  useEffect(() => {
    if (fetchedSections) {
      setSections(fetchedSections);
      if (areSectionsFetchedAlready.current) {
        const lastSection = fetchedSections[fetchedSections.length - 1];
        if (lastSection && sectionCountRef.current !== fetchedSections.length) {
          //  setVideoPlayerSource(lastSection);
          setCurrentSectionId(lastSection.id);
        }
      } else {
        areSectionsFetchedAlready.current = true;
        const firstSection = fetchedSections[0];
        if (firstSection) {
          //  setVideoPlayerSource(firstSection);
          setCurrentSectionId(firstSection.id);
        }
      }

      sectionCountRef.current = fetchedSections.length;
    }
  }, [fetchedSections, setCurrentSectionId, setSections]);

  useEffect(() => {
    if (fetchedSections) {
      const section = fetchedSections.find(
        (sect) => sect.id === currentSectionId
      );

      if (section) {
        if (section.type === 'question') {
          setFetchedQuestionData(section?.Question);
          // setIsQuestionFormVisible(true);
        } else {
          setVideoPlayerSource(section);
          setIsQuestionFormVisible(false);
        }
      }
    }
  }, [
    currentSectionId,
    fetchedSections,
    setIsQuestionFormVisible,
    setVideoPlayerSource,
  ]);

  useEffect(() => {
    const getSectionProcessing = ({ sectionId, percentage }) => {
      setProcessingProgress({ sectionId, percentage });

      if (percentage === 100) {
        refetch();
        setProcessingProgress({ sectionId: '', percentage: 0 });
      }
    };

    socket.on('processing', getSectionProcessing);

    return () => {
      socket.removeListener('processing', getSectionProcessing);
    };
  }, [processingProgress, refetch, socket]);

  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  // const sectionsCount = Math.floor(width / (232 + 40)) || 1;

  const showPlaceholder = useCallback(
    (title, placeholderType, previousSectionId) => {
      if (!sections) {
        setFirstPlaceholderTitle(placeholderType);
      } else {
        if (previousSectionId) {
          const prevSectionIndex = sections.findIndex(
            (section) => section.id === previousSectionId
          );

          if (prevSectionIndex !== -1) {
            setSections((prevSections) => [
              ...prevSections.slice(0, prevSectionIndex + 1),
              {
                id: Math.random(),
                path: 'placeholder',
                title: title || 'Section Placeholder',
                placeholderType,
                prevSectionId: previousSectionId,
              },
              ...prevSections.slice(prevSectionIndex + 1),
            ]);

            setPrevSectionId(previousSectionId);

            return;
          }
        }

        setSections((prevSections) => [
          ...prevSections,
          {
            id: Math.random(),
            path: 'placeholder',
            title: title || 'Section Placeholder',
            placeholderType,
          },
        ]);

        setIsSectionAutoScrollDisabled(false);
      }
    },
    [
      sections,
      setFirstPlaceholderTitle,
      setIsSectionAutoScrollDisabled,
      setSections,
    ]
  );

  const hidePlaceholder = useCallback(() => {
    if (!sections) {
      setFirstPlaceholderTitle(false);
    } else {
      setSections((prevSections) =>
        prevSections.filter((section) => section.path !== 'placeholder')
      );
    }
  }, [sections, setFirstPlaceholderTitle, setSections]);

  const stopAllTracks = useCallback(() => {
    mediaStreams.forEach((mediaRecorder) =>
      mediaRecorder.getTracks().forEach((track) => {
        track.stop();
      })
    );
    mediaStreams = [];
  }, []);

  const stopVideoPlayer = useCallback(() => {
    if (videoPlayerSource === 'stream' || videoPlayerSource === 'audio') {
      setIsRecording(false);

      if (mediaRecorderRef.current?.state === 'recording') {
        mediaRecorderRef.current.stop();
      } else {
        streamRef.current?.getTracks().forEach((track) => {
          track.stop();
        });
        setIsCameraEnabled(false);
        setIsScreenEnabled(false);
        setIsAudioEnabled(false);
        hidePlaceholder();
        setVideoPlayerSource(sections ? sections[sections.length - 2] : null);
      }

      stopAllTracks();

      if (isCameraEnabled) {
        setCameraHasStopped(true);
      }
      // else if (isScreenEnabled) {
      //   // setScreenHasStopped(true);
      // }
    } else if (videoSectionPlayerRef.current) {
      videoSectionPlayerRef.current.pause();
      videoSectionPlayerRef.current.currentTime = 0;
    }
  }, [
    hidePlaceholder,
    isCameraEnabled,
    sections,
    setVideoPlayerSource,
    stopAllTracks,
    videoPlayerSource,
  ]);

  const { mutate: toggleMeetingTrashStatusMutation } = useMutation(
    MeetingService.toggleMeetingTrashStatus,
    {
      onSuccess: () => {
        navigate('/');
      },
    }
  );

  const { mutate: createMeeting } = useMutation(MeetingService.createMeeting, {
    onSuccess: (meeting) => {
      setMeetingId(meeting.id);
      createSection({
        meetingId: meeting.id,
        file,
        question,
        container: isSafari ? 'mp4' : 'webm',
        title: sectionTitle,
        image: audioImage,
        setUploadProgress,
      });
      setFile(null);

      if (isMobile) {
        setIsFileUploadProgressModalVisible(true);
        // navigate('/my-videos?tab=mobile uploads');
        return;
      }

      setQuestion(null);
      setIsQuestionFormVisible(false);
      navigate(`/my-videos/${meeting.id}/edit`, {
        replaceState: true,
      });
    },
    onError: (error) => {
      console.log(error);
      notifyError(error?.response?.data?.message);
    },
  });

  // When mobile upload finished close the upload progress modal and redirect to my videos
  useEffect(() => {
    if (uploadProgress === 100 && isMobile) {
      setIsFileUploadProgressModalVisible(false);
      navigate('/my-videos?tab=mobile uploads');
    }
  }, [isMobile, navigate, uploadProgress]);

  const { mutateAsync: createMeetingAsync } = useMutation(
    MeetingService.createMeeting
  );

  const startCameraMediaRecorder = useCallback(() => {
    setVideoPlayerSource('stream');

    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: {
          width: { ideal: 1920, max: 1920 },
          height: { ideal: 1080, max: 1080 },
        },
      })
      .then((stream) => {
        streamRef.current = stream;
        videoPlayerRef.current.srcObject = stream;
        videoPlayerRef.current.onloadedmetadata = () => {
          if (!videoPlayerRef.current) {
            return;
          }
          videoPlayerRef.current.play();
          setIsCameraEnabled(true);
        };

        mediaStreams.push(stream);

        let mimeType = 'video/webm;codecs=vp9';
        if (isFirefox) {
          mimeType = 'video/webm;codecs:vp9';
        } else if (isSafari) {
          mimeType = 'video/mp4';
        }

        const options = {
          mimeType,
        };
        mediaRecorderRef.current = new MediaRecorder(stream, options);
        chunks = [];

        mediaRecorderRef.current.addEventListener('dataavailable', (e) => {
          if (e.data.size > 0) {
            chunks.push(e.data);
          }
        });

        mediaRecorderRef.current.addEventListener('stop', () => {
          stream.getTracks().forEach((track) => {
            track.stop();
          });

          const blob = new Blob(chunks, {
            type: 'video/webm',
          });

          setIsCameraEnabled(false);
          setFile(blob);
        });
      })
      .catch(() => hidePlaceholder());
  }, [hidePlaceholder, isFirefox, isSafari, setFile, setVideoPlayerSource]);

  const startScreenMediaRecorder = useCallback(
    (callback) => {
      setVideoPlayerSource('stream');

      navigator.mediaDevices
        .getDisplayMedia({
          // audio: true,
          video: {
            mediaSource: 'screen',
            width: { ideal: 1920, max: 1920 },
            height: { ideal: 1080, max: 1080 },
          },
        })
        .then(async (stream) => {
          const audio = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false,
          });

          const combinedStream = new MediaStream([
            ...stream.getTracks(),
            ...audio.getTracks(),
          ]);

          mediaStreams.push(stream);

          streamRef.current = combinedStream;
          videoPlayerRef.current.srcObject = stream;
          videoPlayerRef.current.onloadedmetadata = () => {
            if (!videoPlayerRef.current) {
              return;
            }
            videoPlayerRef.current.play();
            setIsScreenEnabled(true);
          };

          let mimeType = 'video/webm;codecs=vp9';
          if (isFirefox) {
            mimeType = 'video/webm;codecs:vp9';
          } else if (isSafari) {
            mimeType = 'video/mp4';
          }

          const options = {
            mimeType,
          };
          mediaRecorderRef.current = new MediaRecorder(combinedStream, options);
          chunks = [];

          if (callback) {
            callback();
          }

          mediaRecorderRef.current.addEventListener('dataavailable', (e) => {
            if (e.data.size > 0) {
              chunks.push(e.data);
            }
          });

          mediaRecorderRef.current.addEventListener('stop', () => {
            combinedStream.getTracks().forEach((track) => {
              track.stop();
            });

            const blob = new Blob(chunks, {
              type: 'video/webm',
            });
            setIsScreenEnabled(false);
            setFile(blob);
          });
        })
        .catch(() => hidePlaceholder());
    },
    [hidePlaceholder, isFirefox, isSafari, setFile, setVideoPlayerSource]
  );

  const startAudioMediaRecorder = useCallback(() => {
    setVideoPlayerSource('audio');

    navigator.mediaDevices
      .getUserMedia({
        audio: true,
      })
      .then((stream) => {
        streamRef.current = stream;
        mediaStreams.push(stream);

        const mimeType = isSafari ? 'audio/mp4' : 'audio/webm';
        const options = {
          mimeType,
        };

        mediaRecorderRef.current = new MediaRecorder(stream, options);
        chunks = [];

        setIsAudioEnabled(true);

        mediaRecorderRef.current.addEventListener('dataavailable', (e) => {
          if (e.data.size > 0) {
            chunks.push(e.data);
          }
        });

        mediaRecorderRef.current.addEventListener('stop', () => {
          stream.getTracks().forEach((track) => {
            track.stop();
          });

          const blob = new Blob(chunks, {
            type: 'audio/webm',
          });

          setIsAudioEnabled(false);
          setFile(blob);
        });
      })
      .catch(() => hidePlaceholder());
  }, [hidePlaceholder, isSafari, setFile, setVideoPlayerSource]);

  const startScreen = useCallback(
    (callback) => {
      setIsRecording(false);
      startScreenMediaRecorder(callback);
      setIsCameraEnabled(false);
      setIsAudioEnabled(false);
    },
    [startScreenMediaRecorder]
  );

  const startVideo = useCallback(() => {
    setIsRecording(false);
    startCameraMediaRecorder();
    setIsScreenEnabled(false);
    setIsAudioEnabled(false);
  }, [startCameraMediaRecorder]);

  const startAudio = useCallback(() => {
    setIsRecording(false);
    startAudioMediaRecorder();
    setIsScreenEnabled(false);
    setIsCameraEnabled(false);
  }, [startAudioMediaRecorder]);

  const showQuestionForm = useCallback(() => {
    setIsQuestionFormVisible(true);
    setFetchedQuestionData(null);
  }, [setIsQuestionFormVisible]);

  const showAssignSectionForm = useCallback(() => {
    setIsAssignSectionFormVisible(true);
  }, []);

  const hideAssignSectionForm = useCallback(() => {
    setIsAssignSectionFormVisible(false);
  }, []);

  const closeAssignedSection = async () => {
    try {
      await MeetingService.closeAssignedSection(meetingId);
      setIsSectionsCompletePopupVisible(true);
      setTimeout(() => {
        navigate('/my-videos');
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  const hideQuestionAndAssignSectionForms = useCallback(() => {
    setIsAssignSectionFormVisible(false);
    setIsQuestionFormVisible(false);
  }, [setIsQuestionFormVisible]);

  const previewMeeting = async () => {
    setIsPreparingPreview(true);
    await MeetingService.previewMeeting(meetingId);
    setIsPreparingPreview(false);
    navigate(`/my-videos/${meetingId}/preview`);
  };

  /*  useEffect(() => {
    setSectionPageCount(Math.ceil((sections?.length + 1) / sectionsCount));
  }, [sections, sectionsCount]); */

  /* useEffect(() => {
    setCurrentSectionsPage(0);
  }, [sectionsPageCount]); */

  // Load empty video source into video section preview if section was deleted
  useEffect(() => {
    if (videoPlayerSource === null) {
      videoSectionPlayerRef.current?.load();
    }
  }, [videoPlayerSource]);

  useEffect(() => {
    if (fetchedMeeting && fetchedMeeting.status !== 'in progress') {
      setMeetingTitle(fetchedMeeting.title);
    }
  }, [fetchedMeeting, setMeetingTitle]);

  // Autosave when there is no meeting created (for video sections)
  useEffect(() => {
    if (!sections && !meetingId && meetingTitle) {
      createMeeting({
        title: meetingTitle,
        folderId,
        type: isMobile ? 'mobile uploads' : 'my videos',
      });
    }
  }, [
    sections,
    meetingId,
    createMeeting,
    meetingTitle,
    //  file,
    folderId,
    isMobile,
  ]);

  // Autosave when there is no meeting created (for question sections)
  useEffect(() => {
    if (!sections && !meetingId && question) {
      createMeeting({
        title: meetingTitle,
        folderId,
        type: isMobile ? 'mobile uploads' : 'my videos',
      });
    }
  }, [
    sections,
    meetingId,
    createMeeting,
    meetingTitle,
    question,
    folderId,
    isMobile,
  ]);

  // Autosave after meeting was created (for video sections)
  useEffect(() => {
    if (isSectionCreationStopped) {
      setFile(null);
      return;
    }

    if (meetingId && file && hasSections) {
      createSection({
        title: sectionTitle,
        meetingId,
        file,
        container: isSafari ? 'mp4' : 'webm',
        image: audioImage,
      });
    }
  }, [
    audioImage,
    createSection,
    file,
    isSafari,
    isSectionCreationStopped,
    meetingId,
    sectionTitle,
    hasSections,
    setFile,
  ]);

  // Autosave after meeting was created (for question sections)
  useEffect(() => {
    if (meetingId && question && hasSections) {
      console.log('creating question section');
      createSection({
        meetingId,
        question,
        title: sectionTitle,
      });
    }
  }, [createSection, question, isSafari, meetingId, hasSections, sectionTitle]);

  useEffect(() => {
    if (isNoTitleWarningVisible) {
      setTimeout(() => {
        setIsNoTitleWarningVisible(false);
      }, 3000);
    }
  }, [isNoTitleWarningVisible]);

  const isOrganizationOwner = user?.orgRole?.access === ORG_ROLES.OWNER;

  const isPublishButtonDisabled =
    (fetchedMeeting?.userRole !== 'creator' && !isOrganizationOwner) ||
    !sections?.length;
  const isShareButtonDisabled =
    fetchedMeeting?.status !== MEETING_STATUSES.PUBLISHED ||
    (fetchedMeeting?.userRole !== 'creator' && !isOrganizationOwner);
  const isDeleteButtonDisabled =
    !meetingId ||
    (fetchedMeeting?.userRole !== 'creator' && !isOrganizationOwner);
  const isPreviewButtonDisabled = !sections?.length || isPreparingPreview;
  const isSectionButtonDisabled = useMemo(
    () =>
      sections?.filter(
        (section) =>
          section.assignedUserId === user?.id || section.userId === user?.id
      ).length < 2,
    [sections, user]
  );

  const openCopyLinkModal = ({ link, passcode, security }) => {
    setIsShareMeetingModalVisible(false);
    setIsCopyLinkModalVisible(true);
    setSharedMeetingAccess({ link, passcode, security });
  };

  const createDefaultSectionName = useCallback((section) => {
    const type = section.placeholderType;

    /*  const previousSectionOfSameType = sections?.filter(
        (sect) => sect.type === type
      );
 */
    const capitalizedType = type.charAt?.(0).toUpperCase() + type.slice?.(1);

    /*  return `${capitalizedType} ${
        previousSectionOfSameType ? previousSectionOfSameType.length + 1 : 1
      }`; */

    return capitalizedType;
  }, []);

  return (
    <Layout hasUserBlock searchDisabled>
      <div className={classes.CreateOrUpdateMeetingPage}>
        <div className={classes.navigationContainer}>
          <Navigation />
        </div>
        <div className={classes.container}>
          {user?.orgRole?.access !== ORG_ROLES.VIEWER && (
            <UploadMenu
              createDefaultSectionName={createDefaultSectionName}
              setSectionTitle={setSectionTitle}
              setFile={setFile}
              setIsUpdateMeetingTitleModalVisible={
                setIsUpdateMeetingTitleModalVisible
              }
            />
          )}
          <div className={classes.innerContainer} ref={innerContainerRef}>
            <div className={classes.meetingForm}>
              <div className={classes.header}>
                <Breadcrumbs breadcrumbsOptions={breadcrumbsOptions} />
                <CreateMeetingButtons
                  isDeleteButtonDisabled={isDeleteButtonDisabled}
                  previewMeeting={previewMeeting}
                  isPreviewButtonDisabled={isPreviewButtonDisabled}
                  isPublishButtonDisabled={isPublishButtonDisabled}
                  sections={sections}
                  setIsPublishMeetingModalVisible={
                    setIsPublishMeetingModalVisible
                  }
                  noContentWarningTimer={noContentWarningTimer}
                  setIsNoContentWarningPopupVisible={
                    setIsNoContentWarningPopupVisible
                  }
                  isShareButtonDisabled={isShareButtonDisabled}
                  setIsSelectShareOptionModalVisible={
                    setIsSelectShareOptionModalVisible
                  }
                  isSectionButtonDisabled={isSectionButtonDisabled}
                  closeAssignedSection={closeAssignedSection}
                  showConfirmActionModal={showConfirmActionModal}
                  toggleMeetingTrashStatusMutation={
                    toggleMeetingTrashStatusMutation
                  }
                  meetingId={meetingId}
                  fetchedMeeting={fetchedMeeting}
                />
              </div>
            </div>
            <div className={classes.mediaContainerContainer}>
              <MediaContainer
                setIsShareMeetingModalVisible={setIsShareMeetingModalVisible}
                isNotAllowedToDeleteSectionMessageVisible={
                  isNotAllowedToDeleteSectionMessageVisible
                }
                setIsNotAllowedToDeleteSectionMessageVisible={
                  setIsNotAllowedToDeleteSectionMessageVisible
                }
                updateQuestionSection={updateQuestionSection}
                setIsSaveSectionPopupVisible={setIsSaveSectionPopupVisible}
                isSaveSectionPopupVisible={isSaveSectionPopupVisible}
                //  saveSectionSuccessMessage={saveSectionSuccessMessage}
                isConfirmSectionRemovalVisible={isConfirmSectionRemovalVisible}
                isCameraEnabled={isCameraEnabled}
                // setIsCameraEnabled={setIsCameraEnabled}
                isScreenEnabled={isScreenEnabled}
                // setIsScreenEnabled={setIsScreenEnabled}
                isAudioEnabled={isAudioEnabled}
                streamPreviewMediaPlayerRef={streamPreviewMediaPlayerRef}
                setIsConfirmSectionRemovalVisible={
                  setIsConfirmSectionRemovalVisible
                }
                deleteSection={deleteSection}
                videoPlayerSource={videoPlayerSource}
                setVideoPlayerSource={setVideoPlayerSource}
                startCameraMediaRecorder={startCameraMediaRecorder}
                startScreenMediaRecorder={startScreenMediaRecorder}
                startScreen={startScreen}
                // streamRef={streamRef}
                videoSectionPlayerRef={videoSectionPlayerRef}
                videoPlayerRef={videoPlayerRef}
                mediaRecorderRef={mediaRecorderRef}
                isRecording={isRecording}
                setIsRecording={setIsRecording}
                hasFetchedMeeting={!!fetchedMeeting}
                hidePlaceholder={hidePlaceholder}
                isQuestionFormVisible={isQuestionFormVisible}
                setIsQuestionFormVisible={setIsQuestionFormVisible}
                setQuestion={setQuestion}
                fetchedQuestionData={fetchedQuestionData}
                setFetchedQuestionData={setFetchedQuestionData}
                isAssignSectionFormVisible={isAssignSectionFormVisible}
                meetingId={meetingId}
                hideAssignSectionForm={hideAssignSectionForm}
                showPlaceholder={showPlaceholder}
                createMeeting={createMeetingAsync}
                folderId={folderId}
                meetingTitle={meetingTitle}
                cameraHasStopped={cameraHasStopped}
                setCameraHasStopped={setCameraHasStopped}
                // screenHasStopped={screenHasStopped}
                // setScreenHasStopped={setScreenHasStopped}
                stopVideoPlayer={stopVideoPlayer}
                isPublishMeetingPopupVisible={isPublishMeetingPopupVisible}
                isNoTitleWarningVisible={isNoTitleWarningVisible}
                setIsNoTitleWarningVisible={setIsNoTitleWarningVisible}
                refetchSections={refetch}
                isSectionsCompletePopupVisible={isSectionsCompletePopupVisible}
                setMeetingId={setMeetingId}
                startAudioMediaRecorder={startAudioMediaRecorder}
                isScreenRecordReminderVisible={isScreenRecordReminderVisible}
                setIsScreenRecordReminderVisible={
                  setIsScreenRecordReminderVisible
                }
                isSafari={isSafari}
                isShareSuccessPopupVisible={isShareSuccessPopupVisible}
                setIsShareSuccessPopupVisible={setIsShareSuccessPopupVisible}
                stopAllTracks={stopAllTracks}
                isPreviewPopupVisible={isPreparingPreview}
                videoPlayerContainerRef={videoPlayerContainerRef}
                audioImageFileBackground={
                  audioImage ? `url(${URL.createObjectURL(audioImage)})` : null
                }
                setAudioImage={setAudioImage}
                isNoContentWarningPopupVisible={isNoContentWarningPopupVisible}
                currentSection={useMemo(() => {
                  return sections?.find((sctn) => sctn.id === currentSectionId);
                }, [sections, currentSectionId])}
              />
            </div>
          </div>
        </div>
        <CreateMeetingFooter
          updateTextSection={updateTextSection}
          updateSectionTitle={updateSectionTitle}
          changeSectionPosition={changeSectionPosition}
          refetchSections={refetch}
          sections={sections}
          setVideoPlayerSource={setVideoPlayerSource}
          currentSectionId={currentSectionId}
          setCurrentSectionId={setCurrentSectionId}
          setIsConfirmSectionRemovalVisible={setIsConfirmSectionRemovalVisible}
          isNotAllowedToDeleteSectionMessageVisible={
            isNotAllowedToDeleteSectionMessageVisible
          }
          setIsNotAllowedToDeleteSectionMessageVisible={
            setIsNotAllowedToDeleteSectionMessageVisible
          }
          startVideo={startVideo}
          startAudio={startAudio}
          currentSectionsPage={currentSectionsPage}
          setCurrentSectionsPage={setCurrentSectionsPage}
          isCameraEnabled={isCameraEnabled}
          isScreenEnabled={isScreenEnabled}
          isAudioEnabled={isAudioEnabled}
          isRecording={isRecording}
          firstPlaceholderTitle={firstPlaceholderTitle}
          showPlaceholder={showPlaceholder}
          showQuestionForm={showQuestionForm}
          setIsQuestionFormVisible={setIsQuestionFormVisible}
          isQuestionFormVisible={isQuestionFormVisible}
          setFetchedQuestionData={setFetchedQuestionData}
          showAssignSectionForm={showAssignSectionForm}
          isAssignSectionFormVisible={isAssignSectionFormVisible}
          meeting={fetchedMeeting}
          setIsNoTitleWarningVisible={setIsNoTitleWarningVisible}
          meetingTitle={meetingTitle}
          stopVideoPlayer={stopVideoPlayer}
          hideQuestionAndAssignSectionForms={hideQuestionAndAssignSectionForms}
          hidePlaceholder={hidePlaceholder}
          setIsScreenRecordReminderVisible={setIsScreenRecordReminderVisible}
          sectionTitle={sectionTitle}
          setSectionTitle={setSectionTitle}
          setMeetingId={setMeetingId}
          createMeeting={createMeetingAsync}
          folderId={folderId}
          setFile={setFile}
          createDefaultSectionName={createDefaultSectionName}
          setIsSectionSavedModalVisible={setIsSectionSavedModalVisible}
          setIsSectionCreationStopped={setIsSectionCreationStopped}
          processingProgress={processingProgress}
          createSection={createSection}
        />

        <UpdateMeetingTitleModal
          show={isUpdateMeetingTitleModalVisible}
          onConfirm={updateMeetingTitle}
          handleClose={() => setIsUpdateMeetingTitleModalVisible(false)}
          title={meetingTitle}
        />
        <RequestReviewModal
          show={isRequestReviewModalVisible}
          handleClose={() => setIsRequestReviewModalVisible(false)}
          meetingId={meetingId}
        />
        <ShareModal
          show={isShareMeetingModalVisible}
          type="meeting"
          handleClose={() => setIsShareMeetingModalVisible(false)}
          data={fetchedMeeting}
          setIsShareSuccessPopupVisible={setIsShareSuccessPopupVisible}
          selectedTimeZone={selectedTimeZone}
          setIsSelectTimeZoneModalVisible={setIsSelectTimeZoneModalVisible}
          setTimeZoneAssignment={setTimeZoneAssignment}
          openCopyLinkModal={openCopyLinkModal}
        />
        <SelectTimeZoneModal
          show={isSelectTimeZoneModalVisible}
          timeZoneAssignment={timeZoneAssignment}
          selectedTimeZone={selectedTimeZone}
          setSelectedTimeZone={setSelectedTimeZone}
          handleClose={() => setIsSelectTimeZoneModalVisible(false)}
        />
        <CopyLinkModal
          show={isCopyLinkModalVisible}
          handleClose={() => setIsCopyLinkModalVisible(false)}
          sharedMeetingAccess={sharedMeetingAccess}
        />
        <PublishMeetingModal
          show={isPublishMeetingModalVisible}
          handleClose={() => setIsPublishMeetingModalVisible(false)}
          updateMeeting={updateMeeting}
          meetingId={meetingId}
          meetingTitle={meetingTitle}
        />
        <SelectShareOptionModal
          show={isSelectShareOptionModalVisible}
          handleClose={() => setIsSelectShareOptionModalVisible(false)}
          openRequestReviewModal={() => {
            setIsRequestReviewModalVisible(true);
            setIsSelectShareOptionModalVisible(false);
          }}
          openShareMeetingModal={() => {
            setIsShareMeetingModalVisible(true);
            setIsSelectShareOptionModalVisible(false);
          }}
        />
        <AlertModal
          show={isSectionSavedModalVisible}
          message='Your section recording has been saved to "My Saved Sections"'
        />
        <FileUploadProgressModal
          show={isFileUploadProgressModalVisible}
          handleClose={() => {
            setIsFileUploadProgressModalVisible(false);
            navigate('/my-videos?tab=mobile uploads');
          }}
          uploadProgress={uploadProgress}
        />
      </div>
    </Layout>
  );
}
