import React, { useEffect, useRef } from 'react';

import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import NavBar from '../NavBar';

import classes from './styles.module.scss';

import logo from '../../../assets/images/snapsop2.png';
import collapse from '../../../assets/images/nav/collapse.svg';
import { useUiStore } from '../../../store';

export default function Sidebar() {
  const wasToggledAutomatically = useRef(false);

  const isSidebarCollapsed = useUiStore((state) => state.isSidebarCollapsed);
  const toggleSidebar = useUiStore((state) => state.toggleSidebar);

  const isNarrowDesktop = useMediaQuery({ maxWidth: 1600 });

  useEffect(() => {
    if (
      isNarrowDesktop &&
      !isSidebarCollapsed &&
      !wasToggledAutomatically.current
    ) {
      toggleSidebar();
      wasToggledAutomatically.current = true;
    }
  }, [isNarrowDesktop, isSidebarCollapsed, toggleSidebar]);

  return (
    <nav
      className={classNames(classes.Sidebar, {
        [classes.collapsed]: isSidebarCollapsed,
      })}
    >
      <div className={classes.logoContainer}>
        <Link to="/">
          <img src={logo} alt="Logo" className={classes.logo} />
        </Link>
      </div>
      <NavBar />
      <div className={classes.collapseButtonContainer}>
        <button
          className={classes.collapseButton}
          onClick={toggleSidebar}
          type="button"
        >
          <img src={collapse} alt="Collapse" />
        </button>
      </div>
    </nav>
  );
}
