import React from 'react';

import { useMediaQuery } from 'react-responsive';

import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

export default function Header({
  hasUserBlock,
  hasSearch,
  searchInput,
  setSearchInput,
  searchDisabled,
}) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return isMobile ? (
    <MobileHeader
      hasUserBlock={hasUserBlock}
      hasSearch={hasSearch}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      searchDisabled={searchDisabled}
    />
  ) : (
    <DesktopHeader
      hasUserBlock={hasUserBlock}
      hasSearch={hasSearch}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      searchDisabled={searchDisabled}
    />
  );
}
